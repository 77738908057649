import { useEffect, useState } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { useDispatch, useSelector } from "react-redux";
import { authenticate, validateOtp } from "../actions/authAction";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Login = () => {
    const [mobileNumber, setMobileNumber] = useState("");
    const {isLoggedIn} = useSelector(state => state.Auth)
    const [error, setError] = useState("");
    const [otp, setOtp] = useState("");
    const [showOtp, setShowOtp] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const triggerOtp = async () => {
        const status = await dispatch(authenticate({mobileNumber}))
        if(status){
            setShowOtp(true)
        }
    }
    useEffect(() => {
        if(isLoggedIn){
            navigate('/home')
        }
    },[isLoggedIn])
    const otpVerification = async () => {
        const status = await dispatch(validateOtp({otp}))
        if(status){
            navigate('/home')
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Invalid OTP',
                icon: 'error',
            })
        }
    }
    return (
        <>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-7">
                        <div className="login-left-banner" />
                            
                    </div>
                    <div className="col-5 px-5 py-5">
                        <div className="d-flex justify-content-center align-items-center">
                            <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/login-image.png" className="login-img" />
                        </div>
                        <div style={{display: showOtp ? 'none' : 'block'}}>
                            <div className="my-4">
                                <h3 className="text-center">Login</h3>
                            </div>
                            <div className="my-4">
                                <div class="offset-2 col-8">
                                    <label for="mobileNumber" class="form-label">Enter the mobile number</label>
                                    <input type="text" id="mobileNumber" class="form-control" placeholder="Enter the mobile number" onChange={(event) => setMobileNumber(event.target.value)} name="mobileNumber" aria-label="Mobile Number" />
                                </div>
                                <div class="offset-2 col-8 my-4">

                                    <input disabled={mobileNumber.length !== 10} type="submit" value="Get OTP" id="getOtp" class="form-control btn btn-success" name="getOtp" aria-label="GET OTP" onClick={() => triggerOtp()} />
                                </div>
                            </div>
                        </div>
                        <div style={{display: showOtp ? 'block' : 'none'}}>
                            <div className="mt-3">
                                <p className="text-center">We have sent you an OTP</p>
                                <p className="text-center">Enter the 6 - digit OTP sent to {mobileNumber} to proceed</p>
                            </div>
                            <div className="mt-3">
                            <div class="offset-2 col-8">
                                    <label for="otp" class="form-label">Enter the OTP</label>
                                    <input type="text" id="otp" onChange={(event) => setOtp(event.target.value) } class="form-control" placeholder="Enter the OTP" name="otp" aria-label="otp" />
                                </div>
                                <div class="offset-2 col-8 mt-3">
                                    <input type="submit" disabled={otp.length!==6} value="SUBMIT" id="submit" class="form-control btn btn-success" name="submit" aria-label="submit" onClick={() => otpVerification()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Login