import Api from "../lib/Api"
import { AUTH_SUCCESS, AUTH_FAILURE, TOGGLE_LOADER, VALIDATE_OTP_SUCCESS, VALIDATE_OTP_FAILURE } from "./types"


export const authenticate = (body) => async (dispatch) => {
  dispatch({
    type: TOGGLE_LOADER,
    payload: true
  })
  try {
    const response = await Api.post(body, '/hli/auth/otp')
    if (response.status === 'success') {

      dispatch({
        type: AUTH_SUCCESS,
        payload: response.data
      })
      return true
    } else {
      dispatch({
        type: AUTH_FAILURE,
        payload: response.data
      })
      return false
    }
  } catch (e) {
    dispatch({
      type: AUTH_FAILURE,
      payload: e.message
    })
    return false
  }
}

export const validateOtp = (otp) => async (dispatch) => {
  const body = otp
  try {
    const response = await Api.post(body, '/hli/auth/otp/validate')
    if (response.status === 'success') {
      dispatch({
        type: VALIDATE_OTP_SUCCESS,
        payload: response.data
      })
      return response.data
    } else {
      dispatch({
        type: VALIDATE_OTP_FAILURE,
        payload: response.data
      })
      return false
    }
  } catch (e) {
    dispatch({
      type: VALIDATE_OTP_FAILURE,
      payload: e.message
    })
    return false
  }
}


export const triggerCalculatorOtp = (body) => async (dispatch) => {
    dispatch({
      type: TOGGLE_LOADER,
      payload: true
    })
    try {
      const response = await Api.post(body, '/hli/calculator/otp')
      if (response.status === 'success') {

        return response.data
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  export const validateCalculatorOtp = (body) => async (dispatch) => {
    dispatch({
      type: TOGGLE_LOADER,
      payload: true
    })
    try {
      const response = await Api.post(body, '/hli/calculator/otp/validate')
      if (response.status === 'success') {

        return response.data
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }


  export const resendCalculatorOtp = (body) => async (dispatch) => {
    dispatch({
      type: TOGGLE_LOADER,
      payload: true
    })
    try {
      const response = await Api.post(body, '/hli/calculator/otp/resend')
      if (response.status === 'success') {

        return response.data
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }