import Login from '../pages/Login';
import Home from '../pages/Home';
import Eligibility from '../pages/Eligibility';
import {
  createBrowserRouter,

} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/home",
    element: <Home />
  },
  {
    path: "/eligibility",
    element: <Eligibility />
  },
]);

export default router