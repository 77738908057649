import { AUTH_SUCCESS, AUTH_FAILURE, VALIDATE_OTP_SUCCESS, USER_DATA_SUCCESS, LOGOUT, UPDATE_AUTH_TOKEN } from "../actions/types"

const initialState = {
    accessToken: '',
    user: {},
    isLoggedIn: false
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SUCCESS:
            return Object.assign({}, state, {
                message: action.payload
            })
        case AUTH_FAILURE:
            return Object.assign({}, state, {
                message: action.payload
            })
        case USER_DATA_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload
            })
        case VALIDATE_OTP_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload,
                isLoggedIn: true,
            })
        case UPDATE_AUTH_TOKEN:
            return Object.assign({}, state, {
                accessToken: action.payload.accessToken
            }) 
        case LOGOUT:
            return Object.assign({}, state, {
                accessToken: '',
                user: {},
                isLoggedIn: false
            })
        default:
            return state
    }
}

export default AuthReducer;
