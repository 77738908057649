import { useDispatch, useSelector } from "react-redux"
import { LOGOUT } from "../actions/types";

const Header = () => {
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.Auth)
    const doLogout = () => {
        dispatch({
            type: LOGOUT
        })
    }
    return (
        <>
            <div className="container-fluid header-bg">
                {isLoggedIn && (
                    <div className="d-flex justify-content-end align-items-center px-2" style={{ height: '100%' }}>
                        <input type="button" className="btn btn-small btn-success" value="Logout" onClick={() => doLogout()} />
                    </div>
                )}
            </div>
            <div className="container-fluid header-line"></div>
        </>
    )
}

export default Header