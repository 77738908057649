import AuthReducer from "./authReducer";
import {combineReducers} from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
const rootReducer = combineReducers({
    Auth: AuthReducer,
})
const rootPersistConfig = {
    key: 'root',
    storage,
    whitelist: ['Auth'],
    // blacklist: [''],
    stateReconciler: autoMergeLevel2,
}
export default persistReducer(rootPersistConfig, rootReducer);