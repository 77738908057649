import { useLocation, useNavigate, useParams } from "react-router-dom"
import Header from "../components/Header"
import { formatCurrency } from "../helpers/formatter"
import { useSelector } from "react-redux"
import { useEffect } from "react"

const Eligibility = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const result = location.state || {} 
    const { isLoggedIn } = useSelector(state => state.Auth)
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/')
        }
    }, [isLoggedIn, navigate])
    return (
        <>
            <Header />
            <div className="container mt-5">
                <div>
                    <h1 className="title">Eligibility Calculator</h1>
                </div>
                <div className="row">
                    <div className="col-6 my-5 calculator p-5">
                        <div className="row">
                            <div className="col-9 d-flex flex-column justify-content-center">
                                {(result.isPremiumFinanceEligible || result.isLoanOnInsuranceEligible) && (
                                    <>
                                        <p className="title" style={{fontSize:32, fontWeight:500}}>Congratulations !</p>
                                        <p>YOUR POLICY ELIGIBLE FOR</p>
                                    </>
                                )}
                                {(!result.isPremiumFinanceEligible && !result.isLoanOnInsuranceEligible) && (
                                    <p>YOUR POLICY IS NOT ELIGIBLE FOR ANY OF THE SERVICE</p>
                                )}

                            </div>
                            <div className="col-3">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/result-icon.png" />
                            </div>
                        </div>
                        {result.isPremiumFinanceEligible && (
                            <div className="row eligibility-box my-3 p-3">
                                <div className="col-12">
                                    <p className="title">PREMIUM FINANCING</p>
                                    <p>Eligible Loan Amount: <span className="currency">₹ {formatCurrency(result.eligiblePremiumFinance)}</span></p>
                                    <p>Upfront Amount:<span className="currency"> ₹ {formatCurrency(result.upfront)}</span></p>
                                </div>
                            </div>
                        )}
                        {result.isLoanOnInsuranceEligible && (
                            <div className="row eligibility-box my-3 p-3">
                                <div className="col-12">
                                    <p className="title">LOAN ON INSURANCE</p>
                                    <p>Loan Eligible Amount: <span className="currency">₹ {formatCurrency(result.eligibleLoanAmount)}</span></p>
                                </div>
                            </div>
                        )}
                        <div className="row  my-3 p-3">
                            <div className="offset-4">
                            <input onClick={() => navigate('/home')} type="button" className="btn btn-success" value="BACK" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 my-5">
                        <div className="p-5">
                            <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/why-briezi.png" className="why-briezi-img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Eligibility