export const nformatter = num => {
    if (num.toString().length <= 3) {
      return '₹ ' + num;
    }
    if (num.toString().length >= 4 && num.toString().length <= 5) {
      return '₹ ' + parseFloat(num / 1000).toFixed(2) + 'K';
    }
    if (num.toString().length >= 6 && num.toString().length <= 7) {
      return '₹ ' + parseFloat(num / 100000).toFixed(2) + 'L';
    }
    if (num.toString().length >= 8) {
      return '₹ ' + parseFloat(num / 10000000).toFixed(2) + ' Cr';
    }
  };
  
  export const maskPolicyNumber = n => {
    if (n) {
      return (
        n.slice(0, 2) +
        n.slice(2, n.length - 2).replace(/\d/g, 'X') +
        n.slice(n.length - 2)
      );
    }
  };
  
  export const maskBankAccNum = n => {
    if (n) {
      return n.slice(0, n.length - 4).replace(/\d/g, 'X') + n.slice(n.length - 4);
    }
  };
  
  export const formatCurrency = val => {
    if (val) {
      return new Intl.NumberFormat('en-IN', {currency: 'INR'}).format(val);
    } else {
      return 0;
    }
  };
  