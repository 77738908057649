import { useDispatch, useSelector } from "react-redux"
import Header from "../components/Header"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { resendCalculatorOtp, triggerCalculatorOtp, validateCalculatorOtp } from "../actions/authAction"
import Swal from "sweetalert2"

const Home = () => {
    const { isLoggedIn } = useSelector(state => state.Auth)
    const [otp, setOtp] = useState("");
    const [leadId, setLeadId] = useState("");
    const [showOtp, setShowOtp] = useState(false)
    const [seconds, setSeconds] = useState(60);
    const [minutes, setMinutes] = useState(0);
    const [canResend, setCanResend] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [info, setInfo] = useState({
        policyStatus: 'Inforce',
        premium: 0,
        paymentFrequency: 'Yearly',
        fundValue: 0,
        pendingPremium: 0,
        commencementDate: moment(),
        numberOfPendingPremium: 0,
        mobileNumber: ''

    });
    const updateInfo = (name, val) => {
        setInfo({
            ...info,
            [name]: val
        })
    }
    const [error, setError] = useState({
        policyStatus: {
            error: false,
            message: ""
        },
        premium: {
            error: true,
            message: "Premium required"
        },
        paymentFrequency: {
            error: false,
            message: ""
        },
        fundValue: {
            error: true,
            message: "Fund Value Required"
        },
        pendingPremium: {
            error: true,
            message: "Pending Premium Required"
        },
        commencementDate: {
            error: false,
            message: ""
        },
        numberOfPendingPremium: {
            error: false,
            message: ""
        },
        mobileNumber: {
            error: true,
            message: "Mobilenumber required"
        }
    })
    const updateError = (name, err) => {
        setError({
            ...error,
            [name]: err
        })

    }
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/')
        }
    }, [isLoggedIn, navigate])

    const canProceed = () => {
        console.log(error);
        const flatError = Object.values(error).flat();
        const isInValid = flatError.every(e => e.error === false);
        return !isInValid;
    };

    useEffect(() => {

        if(showOtp){
            let myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval);
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                    if (minutes === 0 && seconds === 0) {
                     
                        setCanResend(true);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(myInterval);
            };
        }
    }, [canResend, seconds, minutes, showOtp]);

    const triggerOtp = async () => {
        const status = await dispatch(triggerCalculatorOtp(info))
        if (status) {
            setStartTimer(true)
            setLeadId(status._id)
            setShowOtp(true)
        } else {
            setLeadId("")
            setShowOtp(false)
        }
    }

    const validateOtp = async () => {

        const status = await dispatch(validateCalculatorOtp({ leadId, otp }))
        if (status) {
            setShowOtp(true)
            navigate('/eligibility', { state: status })
        } else {
            Swal.fire({
                title: 'Error',
                text: 'Invalid OTP',
                icon: 'error',
            })
        }
    }

    const resendOtp = () => {
        dispatch(resendCalculatorOtp({leadId}))
        setSeconds(59);
        setCanResend(false)
    }

    const faq = [
        {
            q: "Are loans available against any type of insurance policy for paying premiums?",
            a: "No, Briezi's Loan to Pay Premium service is available for Unit Linked Insurance Plans (ULIPs) and traditional policies issued by HDFC Life Insurance."
        },
        {
            q: "How long does it take to disburse the premium loan amount?",
            a: "Upon approval from insurer, the premium loan amount will be disbursed immediately to insurance company making sure that you will continue to get the risk coverage."
        },
        {
            q: "What happens if policyholder fail to repay the premium loan amount on time?",
            a: " Late repayment may result in penalties  or additional charges, and could impact your insurance coverage. It is crucial to adhere to the repayment schedule."
        },
        {
            q: "Can policyholders prepay the loan amount before the end of the loan tenure?",
            a: "Yes, they have the option to prepay the loan amount at any time before the end of the loan tenure with 2% of pre-closure charges"
        }
    ]

    return (
        <>
            <Header />
            <div className="container mt-5">
                <div>
                    <h1 className="title">Eligibility Calculator</h1>
                </div>
                <div className="row">
                    <div className="col-6 my-5">
                        <div className="calculator p-5">
                            <div className="row my-5">
                                <div className="col">
                                    <label for="policyStatus" className="form-label">Policy status</label>
                                    <select className="form-select"
                                        onChange={(event) => {

                                            updateInfo(event.target.name, event.target.value)
                                            if (event.target.value.trim() != "") {
                                                updateError(event.target.name, { error: false, message: "" })
                                            } else {
                                                updateError(event.target.name, { error: true, message: "Select valid policy status" })
                                            }
                                        }}
                                        name="policyStatus"
                                        id="policyStatus"
                                        aria-label="Default select policy status">
                                        <option value="Inforce">Inforce</option>
                                        <option value="Discontinued">Discontinued</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <label for="premium" className="form-label">Premium</label>
                                    <input
                                        type="number"
                                        id="premium"
                                        name="premium"
                                        onChange={(event) => {
                                            updateInfo(event.target.name, event.target.value)
                                            if (parseFloat(event.target.value) > 0) {
                                                updateError(event.target.name, { error: false, message: "" })
                                            } else {
                                                updateError(event.target.name, { error: true, message: "Enter valid premium" })
                                            }
                                        }}
                                        className="form-control"
                                        placeholder="Premium"
                                        aria-label="Premium" />
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col">
                                    <label for="paymentFrequency" className="form-label">Payment Frequency</label>
                                    <select className="form-select"
                                        name="paymentFrequency"
                                        onChange={(event) => {
                                            updateInfo(event.target.name, event.target.value)
                                        }}
                                        id="paymentFrequency"
                                        aria-label="Default select payment frequency">
                                        <option value="Yearly">Yearly</option>
                                        <option value="Half Yearly">Half Yearly</option>
                                        <option value="Quarterly">Quarterly</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Single">Single</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <label for="fundValue" className="form-label">{info.policyStatus === 'Inforce'? 'Fund Value' : 'Discontinued Fund'}</label>
                                    <input type="text"
                                        id="fundValue"
                                        className="form-control"
                                        placeholder={info.policyStatus === 'Inforce'? 'Fund Value' : 'Discontinued Fund'}
                                        name="fundValue"
                                        onChange={(event) => {
                                            updateInfo(event.target.name, event.target.value)
                                            if (parseFloat(event.target.value) > 0) {
                                                updateError(event.target.name, { error: false, message: "" })
                                            } else {
                                                updateError(event.target.name, { error: true, message: "Enter valid fund value" })
                                            }
                                        }}
                                        aria-label="Last name" />
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col">
                                    <label for="pendingPremium" className="form-label">Pending Premium</label>
                                    <input type="text"
                                        id="pendingPremium"
                                        className="form-control"
                                        placeholder="Pending Premium"
                                        name="pendingPremium"
                                        onChange={(event) => {
                                            updateInfo(event.target.name, event.target.value)
                                            if (parseFloat(event.target.value) >= 0) {
                                                updateError(event.target.name, { error: false, message: "" })
                                            } else {
                                                updateError(event.target.name, { error: true, message: "Enter valid pending premium" })
                                            }
                                        }}
                                        aria-label="Pending Premium" />
                                </div>
                                <div className="col">
                                    <label for="commencementDate" className="form-label">Commencement Date</label>
                                    <input
                                        type="date"
                                        name="commencementDate"
                                        id="commencementDate"
                                        className="form-control"
                                        onChange={(event) => updateInfo(event.target.name, event.target.value)}
                                        placeholder="Commencement Date"
                                        aria-label="Commencement Date" />
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col">
                                    <label for="numberOfPendingPremium" className="form-label">No. of pending premium</label>
                                    <input type="text"
                                        id="numberOfPendingPremium"
                                        name="numberOfPendingPremium"
                                        onChange={(event) => {
                                            updateInfo(event.target.name, event.target.value)
                                            if (parseFloat(event.target.value) >= 0) {
                                                updateError(event.target.name, { error: false, message: "" })
                                            } else {
                                                updateError(event.target.name, { error: true, message: "Enter valid number of pending premium" })
                                            }
                                        }}
                                        className="form-control"
                                        placeholder="No. of pending premium"
                                        aria-label="No. of pending premium" />
                                </div>
                                <div className="col">
                                    <label for="mobileNumber" className="form-label">Customer mobile number</label>
                                    <input
                                        type="text"
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        onChange={(event) => {
                                            updateInfo(event.target.name, event.target.value)
                                            if (event.target.value.length === 10) {
                                                updateError(event.target.name, { error: false, message: "" })
                                            } else {
                                                updateError(event.target.name, { error: true, message: "Enter valid mobile number" })
                                            }
                                        }}
                                        className="form-control"
                                        placeholder="Mobile Number"
                                        aria-label="Mobile Number" />
                                </div>
                            </div>
                            <div className="row" style={{ display: showOtp ? 'none' : 'block' }}>
                                <div class="col-6 offset-3 mt-3">
                                    <input disabled={canProceed()} onClick={() => triggerOtp()} type="submit" value="Get OTP" id="submit" class="form-control btn btn-success" name="submit" aria-label="submit" />
                                </div>
                            </div>
                            <div className="row otp-container py-4 px-2 mt-3" style={{ display: !showOtp ? 'none' : 'flex' }}>
                                <div class="col-6">
                                    <input type="text" onChange={(event) => setOtp(event.target.value)} id="otp" name="otp" className="form-control" placeholder="Enter the OTP" aria-label="otp" />
                                    <div className="d-flex justify-content-between mt-2">

                                        <small>{`${minutes}:${seconds}`}</small>

                                        <input type="button" disabled={!canResend} className="btn btn-link resend-otp" value={"Resend OTP"} onClick={() => resendOtp()} />
                                        
                                    </div>
                                </div>
                                <div class="col-6">
                                    <input type="submit" disabled={otp.length != 6} onClick={() => validateOtp()} value="Submit" id="submit" class="form-control btn btn-success" name="submit" aria-label="submit" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 my-5">
                        <div className="p-5">
                            <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/why-briezi.png" className="why-briezi-img" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid why-ipf">
                <div className="container why-ipf-box">
                    <h3 className="my-5">Why Premium Financing?</h3>
                    <div className="col-6">
                        <div className="d-flex justify-content-start align-items-start">
                            <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                            <p className="mx-4"> <b className="bold">Continuation of Risk Coverage:</b> Keep your insurance coverage without the immediate financial burden, ensuring you and your loved ones stay protected.</p>
                        </div>
                        <div className="d-flex justify-content-start align-items-start">
                            <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                            <p className="mx-4">  <strong className="bold">Flexible Repayment Options:</strong> Flexible repayment options that suits policyholders financial needs.</p>
                        </div>
                        <div className="d-flex justify-content-start align-items-start">
                            <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                            <p className="mx-4">  <strong className="bold">Quick and Easy Process:</strong> Simple application process with instant approval.</p>
                        </div>
                        <div className="d-flex justify-content-start align-items-start">
                            <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                            <p className="mx-4">  <strong className="bold">No additional collaterals:</strong> The fund value of the policy is sufficient to finance premiums.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid what-ipf">
                <div className="container row what-ipf-box">

                    <div className="col-6">
                        <video width="80%" controls poster="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/video-thumb.png">
                            <source src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/premium-finance.mp4" type="video/mp4" />
                                
                            </video>

                            </div>
                            <div className="col-6">
                                <h3 className="my-5">What is Premium Financing?</h3>
                                <p>Premium Financing is a convenient financial solution offered by Briezi that helps you pay your insurance premiums with easy EMis. It ensures your insurance coverage without the immediate financial burden, ensuring you and your loved ones stay protected.</p>
                            </div>
                    </div>
                </div>
                <div className="container-fluid feature-ipf">
                    <div className="container feature-ipf-box">

                        <div className="offset-6 col-6">
                            <h3 className="my-5">Features and Benefits of Premium Financing</h3>
                            <div className="d-flex justify-content-start align-items-start">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                                <p className="mx-4"> <b className="bold">Hassle-Free Application Process:</b>Streamlined application process with minimal documentation requirements for quick approval.</p>
                            </div>
                            <div className="d-flex justify-content-start align-items-start">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                                <p className="mx-4">  <strong className="bold">Get Quick Approval:</strong> Insurer reviews premium financing application and approves it quickly.</p>
                            </div>
                            <div className="d-flex justify-content-start align-items-start">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                                <p className="mx-4">  <strong className="bold">Quick Disbursement:</strong> The funds are disbursed directly to the insurance company, ensuring you get continuous coverage.</p>
                            </div>
                            <div className="d-flex justify-content-start align-items-start">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                                <p className="mx-4">  <strong className="bold">Flexible Repayment Options:  </strong>  Multiple repayment options that suits policyholder’s financial situation</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid eligibility-ipf">
                    <div className="container eligibility-ipf-box">
                        <h3 className="my-5">Eligibility Criteria</h3>
                        <div className="col-6">
                            <div className="d-flex justify-content-start align-items-start">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                                <p className="mx-4"> <b className="bold">Policy Type:</b>The policy must be a Unit Linked Insurance Plan (ULIP) issued by HDFC Life Insurance.</p>
                            </div>
                            <div className="d-flex justify-content-start align-items-start">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                                <p className="mx-4">  <strong className="bold">Policy Tenure:</strong>The policy must have completed a minimum one year of premium payment.</p>
                            </div>
                            <div className="d-flex justify-content-start align-items-start">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                                <p className="mx-4">  <strong className="bold">Policy Value: </strong>The policy must have accumulated sufficient cash value to support the loan amount requested.</p>
                            </div>
                            <div className="d-flex justify-content-start align-items-start">
                                <img src="https://briezi-static.s3.ap-south-1.amazonaws.com/hdfc/chevron.png" />
                                <p className="mx-4">  <strong className="bold">KYC:</strong>Valid Aadhar card, PAN card, Bank Details and Policyholder Selfie</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid faq-ipf">
                    <div className="container faq-ipf-box">
                        <h3 className="my-5 text-center">Frequently Asked Questions</h3>
                        <div className="offset-2 col-8">
                            {faq.map((f) => (
                                <div className="faq-container my-5">
                                    <p className="question"><span className="q">Q. </span>{f.q}</p>
                                    <p className="answer"><span className="q">A. </span>{f.a}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
            )
}

            export default Home